<template>
    <span>
        <a class="button is-primary is-dark is-outlined is-small"
            @click="openDialog(item)"
            :title="'Link to stashdb'">
            <span class="icon">
              <svg width="24pt" height="24pt" viewBox="0 0 240 240" preserveAspectRatio="xMidYMid meet">
                <g transform="matrix(0.1,0,0,-0.1,-3.071616,216.06899)" stroke="none">
                  <path d="m 731,1984 c -288,-84 -525,-155 -526,-156 -1,-2 -46,-59 -99,-126 -54,-68 -96,-125 -94,-127 2,-2 210,-76 463,-164 358,-125 462,-158 471,-148 9,10 252,305 259,315 2,2 18,-5 37,-14 31,-14 38,-14 71,0 l 37,15 141,-169 142,-168 51,18 c 295,103 853,303 858,308 6,6 -157,230 -195,269 -14,14 -1044,304 -1073,302 -10,-1 -255,-70 -543,-155 z m 923,-30 c 199,-59 363,-108 365,-110 2,-2 2,-6 0,-8 -7,-8 -719,-206 -737,-206 -19,0 -743,208 -750,215 -3,3 1,7 9,10 45,16 717,203 733,204 11,1 182,-47 380,-105 z"/>
                  <path d="m 1345,1398 c -3,-7 -4,-229 -3,-494 l 3,-481 492,201 492,201 3,273 c 2,194 0,272 -8,272 -6,0 -174,-54 -373,-119 l -362,-119 -112,139 c -61,76 -115,139 -119,139 -5,0 -11,-6 -13,-12 z"/>
                  <path d="m 1086,1264 c -69,-86 -114,-133 -123,-130 -34,13 -720,236 -726,236 -4,0 -7,-121 -7,-270 0,-248 1,-270 18,-279 20,-11 972,-401 978,-401 6,0 5,493 -3,748 -4,157 -10,232 -17,232 -6,0 -60,-61 -120,-136 z"/>
                </g>
                <path d="m 228.20736,195.88724 c 7.30701,-10.64743 7.30701,-27.89063 0,-38.53804 -6.46638,-9.4225 -16.65739,-10.64744 -24.09374,-2.90213 l -0.20691,0.20729 c -1.86233,1.94104 -2.2891,5.71004 -0.95704,8.40487 1.33209,2.69484 3.91864,3.33558 5.76801,1.39453 l 0.20693,-0.20729 c 4.15142,-4.31552 9.8289,-3.6371 13.4242,1.62067 4.07383,5.93617 4.07383,15.54713 0,21.48332 l -14.51054,21.18178 c -4.07382,5.93617 -10.66953,5.93617 -14.74334,0 -3.60825,-5.25776 -4.07383,-13.53071 -1.11222,-19.56112 l 0.14225,-0.30152 c 1.33208,-2.71368 0.89237,-6.48268 -0.95702,-8.40487 -1.84939,-1.92219 -4.44887,-1.30031 -5.76801,1.39453 l -0.14225,0.30152 c -5.3283,10.81704 -4.48768,25.66691 1.9787,35.08941 7.30702,10.64741 19.14048,10.64741 26.4475,0 z m -67.19861,-4.40974 c -7.30701,10.64744 -7.30701,27.89061 0,38.53805 6.46638,9.4225 16.65739,10.64742 24.09373,2.90213 l 0.20693,-0.2073 c 1.86231,-1.94102 2.28909,-5.71003 0.95702,-8.40487 -1.33208,-2.69483 -3.91863,-3.33557 -5.76801,-1.39454 l -0.20692,0.20731 c -4.15143,4.3155 -9.8289,3.63708 -13.42421,-1.62067 -4.07382,-5.95503 -4.07382,-15.56598 0,-21.50216 l 14.51056,-21.16294 c 4.07381,-5.93619 10.66952,-5.93619 14.74334,0 3.60823,5.25776 4.07381,13.53071 1.11222,19.57995 l -0.14227,0.30152 c -1.33207,2.71369 -0.89235,6.48268 0.95703,8.40487 1.84939,1.9222 4.44886,1.30033 5.76801,-1.39452 l 0.14226,-0.30152 c 5.32829,-10.83587 4.48766,-25.68575 -1.97872,-35.10824 -7.30701,-10.64745 -19.14048,-10.64745 -26.44749,0 z"
                    style="stroke-width:0.09375" />
              </svg>
           </span>
        </a>
    </span>
</template>

<script>
export default {
  name: 'LinkStashdbButton',
  props: { item: Object, objectType: String },  
  data () {
    return {
        isModalActive: false,
        searchResults: [],
        query: "",
        queryString: "",
        isFetching: false,
        }
  },
  computed: {
    buttonClass () {
      return 'button  is-outlined is-small'
    }
  },
  methods: {
    openDialog(item) {
        if (this.objectType == "scene") {
          this.$store.commit('overlay/showSearchStashdbScenes', { item })
        } else {
          this.$store.commit('overlay/showSearchStashdbActors', { item })
        }
      },
    }
}
</script>
    